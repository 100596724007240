   export default [
    {
        name: "admin-withdrawal-view",
        path: "/app/withdrawal/view/id:id",
        props: true,
        meta: {
            group: "administrator"
        },
        component: () => import("@/views/Administrator/Withdrawal/view")
    }
]