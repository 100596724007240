

export default [
    {
        path: "/authorization",
        name: "auth-login",
        component: () => import("@/views/Authorization/v2/Login"),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        }
    },
    {
        path: '/register',
        name: 'auth-register',
        component: () => import('@/views/Authorization/v2/Register.vue'),
        meta: {
          layout: 'full',
          resource: 'Auth',
          redirectIfLoggedIn: true,
        },
    },
    {
        path: '/password-forgot',
        name: 'auth-password-forgot',
        component: () => import('@/views/Authorization/v2/Forgot.vue'),
        meta: {
          layout: 'full',
          resource: 'Auth',
          redirectIfLoggedIn: true,
        },
    },
    {
        path: '/password-reset/:link',
        name: 'auth-password-reset',
        component: () => import('@/views/Authorization/v2/Reset.vue'),
        meta: {
          layout: 'full',
          resource: 'Auth',
          redirectIfLoggedIn: true,
        },
    }
]