import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import User from "@/modules/user/"

Vue.use(VueRouter)
Vue.use(VueMeta)

import authorization from "./authorization"
import site from "./site"
import user from "./user"
import administrator from "./administrator"

import pages from "./pages"

import newDesign from "./newDesign"

//function getDefaultRouterComponent() {
//
//    if( User.isAuth() )
//
//}


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...newDesign,
    ...site,
    ...administrator,
    ...user,
    ...authorization,
    ...pages,
//    {
//      path: '*',
//      redirect: 'error-404',
//    },
  ],
});

router.afterEach((to, from) => {

    document.body.scrollTo({
        x: 0,
        y: 0
    });
    if (to.hash === "") {
    }

    let wrapper = document.getElementsByClassName('content-wrapper');
    if( wrapper && wrapper.length > 0 ) {
        wrapper[0].scrollTop = 0;
    }

    if (["/", "/vacancy"].includes(to.path) || to.meta.md) {
        document.documentElement.setAttribute("md", "true")
    } else {
        document.documentElement.removeAttribute("md")
    }

    if (["/temp404"].includes(to.path) || to.meta.bluebg) {
        document.documentElement.setAttribute("bluebg", "true")
    } else {
        document.documentElement.removeAttribute("bluebg")
    }
//    document.getElementsByClassName('content-wrapper')[0].scrollTo({
//        x: 0,
//        y: 0
//    });

});


router.beforeEach((to, _, next) => {
    const isLoggedIn = User.isAuth();
    if( to.meta.visibility === 'public' ) {
        return next();
    }

    if(!isLoggedIn && !to.meta.redirectIfLoggedIn ) {
        if (to.name === null) {
            if (to.path == '/PageNotFound404') {
                return next({name: 'page-not-found'});
            }
            else {
                location.href = process.env.VUE_APP_BASE_URL+'PageNotFound404'
            }
        }
        else if (to.name == 'page-not-found') {
            return next();
        }
        else if (to.path == '/payment/success') {
            return next();
        }
        else if (to.path == '/payment/error') {
            return next();
        }
        else if (to.path == '/payment/pending') {
            return next();
        }
        else if (to.path == '/payment/pending') {
            return next();
        }
        else {
            return next("/");
        }
    }

    if (to.path == '/payment/success' || to.path == '/payment/error' || to.path == '/payment/pending') {
        return next();
    }

    if(to.meta.redirectIfLoggedIn && isLoggedIn) {
        // return next({ name: "user-dashboard" });
        return next();
    }

    if( to.meta.group === "any" ) {
        return next();
    }

    if( User.isAuth() ) {

        let group = 'user';

        switch( User.self.group ) {
            case 1: group = 'user'; break;
            case 2: group = 'editor'; break;
            case 3: group = 'manager'; break;
            case 4: group = 'agent'; break;
            case 5: group = 'administrator'; break;
        }

        if( group !== to.meta.group && to.meta.group !== "user" ) {
             switch( User.self.group ) {
                case 1: return next({ name: "user-dashboard" });
                case 2: group = 'editor'; break;
                case 3: group = 'manager'; break;
                case 4: group = 'agent'; break;
                case 5: return next({ name: "admin-dashboard" });
            }
        }

    }

    return next();

});

export default router
