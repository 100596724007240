


    export default [
        {
            path: "",
            name: "user-dashboard",
            component: () => import("@/views/User/dashboard/views/main"),
            meta: {
                group: "user"
            }
        },
        {
            path: "miners",
            name: "user-miners-list",
            component: () => import("@/views/User/miners/list"),
            meta: {
                pageTitle: "Майнеры",
                group: "user"
            },
        },
        {
            path: "contracts",
            name: "user-contracts-list",
            component: () => import("@/views/User/contracts/list"),
            meta: {
                pageTitle: "Список контрактов",
                group: "user",
                breadcrumb: [
                    {
                      text: 'Контракты',
                      active: true
                    },
                ],
            }
        },
        { 
            path: "/dashboard/charts/:name",
            name: "user-dashboard-chart-desktop",
            component: () => import("@/views/User/dashboard/dashboard-charts-view"),
            meta: {
                group: "user"
            }
        },
        
    ]