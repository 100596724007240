


    export default [
        {
            path: "",
            name: "admin-miners-list",
            component: () => import("@/views/Administrator/Miners/catalog"),
            meta: {
                pageTitle: "Каталог майнеров",
                group: "administrator"
            },
        },
        {
            path: "add",
            name: "admin-miners-add",
            component: () => import("@/views/Administrator/Miners/add"),
            meta: {
                pageTitle: "Добавить майнер",
                group: "administrator"
            },
        },
        {
            path: "edit",
            name: "admin-miners-update",
            component: () => import("@/views/Administrator/Miners/update"),
            props: true,
            meta: {
                pageTitle: "Редактировать майнер",
                group: "administrator"
            },
        }
    ]