

    export default [
        {
            path: "/old",
            component: () => import("@/views/Viewer"),
            meta: {
                redirectIfLoggedIn: true,
                title: 'Платформа для майнинга криптовалют | Qubity',
            },
            children: [
                {
                    path: "",
                    name: "welcome-page",
                    component: () => import("@/views/Main/v1/"),
                    meta: {
                        redirectIfLoggedIn: true,
                        layout: 'site',
                    },
                },
                {
                    path: "rent-hashrate",
                    name: "user-contracts-rent",
                    component: () => import("@/views/User/calculator/calculator"),
                    meta: {
                        pageTitle: "Аренда хешрейта",
                        redirectIfLoggedIn: true,
                        layout: 'custom',
                    },
                },
                {
                    path: "buy-miners",
                    name: "user-miners-buy",
                    component: () => import("@/views/User/miners/catalog"),
                    meta: {
                        pageTitle: "Покупка майнера",
                        redirectIfLoggedIn: true,
                        layout: 'custom',
                    },
                },
                {
                    path: "buy-miners/item/id:item",
                    name: "user-miners-buy-item",
                    component: () => import("@/views/User/miners/item/"),
                    meta: {
                       redirectIfLoggedIn: true,
                       layout: 'custom',
                    },
                },
            ]
        },
    ]
