
import miners from "./miners/admin";
import news from "./news/admin";
import users from "./users/";
import settings from "./settings/admin";
import errors from "./errors/admin";
import contracts from "./contracts/admin";
// import finance from "./finance/admin";
import tickets from "./tickets/admin";
import withdrawal from "./withdrawal/";
import vacancies from "./vacancies/admin";
import knowledge from "./knowledge/admin";

var routes = [
    { 
        path: "/app",
        name: "admin",
        component: () => import("@/views/Administrator/"),
        meta: {
            pageTitle: "Панель администратора Qubity",
            group: "administrator"
        },
        children: [
            { 
                path: "",
                name: "admin-dashboard",
                component: () => import("@/views/Administrator/Users/list"),
                meta: {
                    pageTitle: "Панель администратора Qubity",
                    group: "administrator"
                },
            },
            {
                path: "miners",
                name: "admin-miners",
                component: () => import("@/views/Administrator/Miners/"),
                meta: {
                    pageTitle: "Майнеры",
                    group: "administrator"
                },
                children: miners
            },
            {
                path: "news",
                name: "admin-news",
                component: () => import("@/views/Administrator/News/"),
                meta: {
                    pageTitle: "Новости",
                    group: "administrator"
                },
                children: news
            },
            {
                path: "wallets",
                name: "admin-finance",
                component: () => import("@/views/Administrator/Finance/Wallets"),
                meta: {
                    pageTitle: "Финансы",
                    group: "administrator"
                },
            },
            {
                path: "withdrawal",
                name: "admin-withdrawal",
                component: () => import("@/views/Administrator/Finance/Withdrawal"),
                meta: {
                    pageTitle: "Вывод средств",
                    group: "administrator"
                },
            },
            {
                path: "referral",
                name: "admin-referral",
                component: () => import("@/views/Administrator/Referral/index"),
                meta: {
                    pageTitle: "Партнерская программа",
                    group: "administrator"
                },
            },
            {
                path: "vacancies",
                name: "admin-vacancies",
                component: () => import("@/views/Administrator/Vacancies/"),
                meta: {
                    pageTitle: "Вакансии",
                    group: "administrator"
                },
                children: vacancies
            },
            {
                path: "knowledge",
                name: "admin-knowledge",
                component: () => import("@/views/Administrator/Knowledge/"),
                meta: {
                    pageTitle: "База знаний",
                    group: "administrator"
                },
                children: knowledge
            },
            {
                path: "analytics",
                name: "admin-analytics",
                component: () => import("@/views/Administrator/Analytics/dashboard"),
                meta: {
                    pageTitle: "Аналитика",
                    group: "administrator"
                }
            },
            {
                path: "orders",
                name: "admin-orders",
                component: () => import("@/views/Administrator/Orders/list"),
                meta: {
                    pageTitle: "Заявки",
                    group: "administrator"
                }
            },

            ...tickets,
            ...contracts,
            ...users,
            ...settings,
            ...withdrawal,
            ...errors
        ]
    },
    
];

export default routes;