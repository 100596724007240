import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import checkView from 'vue-check-view'
import 'zingchart/es6';
import zingchartVue from 'zingchart-vue';

import VueSmoothScroll from 'vue2-smooth-scroll'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// import * as Validate from 'vee-validate'
// import localizeRU from "@/localize/ru/validation";
// import localizeEN from "@/localize/en/validation";

import { showAt } from 'vue-breakpoints'

import textMiddleEllipsis from 'vue-text-middle-ellipsis';
import VueVirtualScroller from 'vue-virtual-scroller'
import VueQrcode from '@chenfengyuan/vue-qrcode';
import VirtualList from 'vue-virtual-scroll-list'
import { VueMaskDirective } from 'v-mask'
import VueMarquee from 'vue-marquee-component';
import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

Vue.directive('mask', VueMaskDirective);

Vue.use(VueVirtualScroller);
Vue.use(textMiddleEllipsis);
Vue.component('qrcode', VueQrcode );
Vue.component('virtual-list', VirtualList );

Vue.use(VueMarquee);
Vue.component("q-btn", QBtn)
Vue.component("q-header", QHeader)

// Validate.localize( "ru", localizeRU );

import CountryFlag from 'vue-country-flag'

Vue.component('country-flag', CountryFlag)

import router from './router'
import store from './store'
import i18n from './libs/i18n'
import VueMeta from 'vue-meta'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/clipboard'
import '@/libs/sweet-alerts'

import LottieView from "@/@core/components/lottie-view/"

Vue.component("Lottie", LottieView );

import vueAwesomeCountdown from 'vue-awesome-countdown'

Vue.use(vueAwesomeCountdown, 'countdown') // Component name, `countdown` and `vac` by default
//
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(checkView);

Vue.component("showAt", showAt);

import Ripple from 'vue-ripple-directive'
Ripple.color = 'rgba(0, 0, 0, 0.1)';

Vue.directive('ripple', Ripple);

Vue.use(VueMeta)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

require('animate.css/animate.css');

import VuejsDialog from 'vuejs-dialog';

// include the default style
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

Vue.use(VuejsDialog, {
  html: true,
  okText: 'Да',
  cancelText: 'Нет',
})

Vue.config.productionTip = false

import $request from "@/modules/request/"
import $user from "@/modules/user/"
import $blockchain from "@/modules/blockchain/"
import QBtn from "@/views/Main/main-design-v2/components/QBtn";
import QHeader from "@/views/Main/main-design-v2/components/QHeader";

Vue.use($request);
Vue.use($user);
Vue.use($blockchain);

Vue.component('zingchart', zingchartVue);
Vue.use(VueSmoothScroll);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
