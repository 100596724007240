

    import $request from "@/modules/request/"

    export default {
            
        workers: {
            details: [],
            total_count: {
                hashrate: 0,
                hashrate1h: 0,
                hashrate24h: 0
            },
            total_hashrate:  {
                hashrate: 0,
                hashrate1h: 0,
                hashrate24h: 0
            },
        },
        
        get(cb = null) {
            $request.get("analytics/workers")
                .then( rsp => {
                    this.workers = rsp;

                    if (cb) cb();
                });
        },
            
    }