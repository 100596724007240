

    import User from "@/modules/user/"
    import jsonToFormData from '@ajoelp/json-to-formdata';    
    import crypto from "./crypto"

    export default {
           
         server: "wss://dev.twidy.ru:1337/",
         host: process.env.VUE_APP_API,
         request: require('axios'),
         io: null,
         access_token: localStorage.getItem("access_token"),
         pk: process.env.VUE_APP_PK,
         customHeaders: {},
         initSocketListiner() {},
         
         headers() {
             
             var headers = {};

             this.access_token = localStorage.getItem("access_token") || sessionStorage.getItem("access_token");

             if(this.access_token) {
                 headers['Authorization'] = this.access_token;
             }
             
             var keys = Object.keys(this.customHeaders);
             
             keys.forEach((key) => {
                if(Object.prototype.hasOwnProperty.call(this.customHeaders, key)) {
                    headers[key] = this.customHeaders[key];
                } 
             });
             
             return headers;
             
         }, 
         
         build(method, endpoint, data, progress ) {
             
            let headers = this.headers();
            headers['Content-Secure'] = 1;
             
            var params = {
               url: this.host + endpoint,
               method: method === "upload" ? "post" : method,
               headers: headers,
               onDownloadProgress: progress
            };
            
            let keys = Object.keys(data);
            
            keys.forEach(key => {
                if(data[key] === null) {
                    delete data[key];
                } 
            });
            
            data = { payload: crypto.encrypt( data, this.access_token || this.pk ) };
     
            switch(method) {
                case 'get' : params['params'] = data; break;
                case 'post' : params['data'] = jsonToFormData(data); break;
            }
                        
            return params;
             
         },
         
         get(endpoint = '', query = {}, progress) {
            return new Promise((resolve, reject) => {
                this.request(this.build('get', endpoint, query, progress )).then((response) => {
                    this.responseHandler(response, resolve, reject);
                }).catch((err) => {
                    reject(err);
                });
            });
         },
            
         post(endpoint = '', formData = {}) {
            return new Promise((resolve, reject) => {
                this.request(this.build('post', endpoint, formData)).then((response) => {
                    this.responseHandler(response, resolve, reject);
                }).catch((err) => {
                    reject(err);
                });
            });
         },
         
         transfer(endpoint = 'media.upload', params, file, onUploadProgress, fileKey = 'file', ) {
             
            var headers = this.headers();
            var formData = new FormData();

            Object.keys(params).forEach(key => { formData.append( key, params[key] ); });

            formData.append( fileKey, file );
            
            headers['Content-Type'] = 'multipart/form-data';
            
            return new Promise((resolve, reject) => {
                this.request.post(this.host + endpoint, formData, {
                    onUploadProgress: onUploadProgress,
                    headers: headers
                }).then( response => {
                    this.responseHandler( response, resolve, reject );
                }).catch(err => reject(err));
            }); 
             
             
         },
         
     
         responseHandler(response, resolve, reject) {
           
            if( this.f7 ) {
               this.f7.dialog.close();
            }            
            
            let result = {};
            
            if( response.config.headers.hasOwnProperty('Content-Secure') && response.data.hasOwnProperty('payload')  ) {
                result = JSON.parse(crypto.decrypt( response.data.payload , this.access_token || this.pk ));
            } else {
                result = response.data;
            }
                        
            if(response.status === 200) {
                               
                if(result.status === 'fail') {
                    
                    this.errorHandler(result.code, result);
                                        
                    reject(result);
                    
                } else {
                    resolve(result.result);
                }
            } else {
                reject(result);
            }
                        
         },
         
         errorHandler(errorCode, errorData) {
             
             switch(errorCode) {
                case 4: this.permissionError(errorCode, errorData); break;
                case 7: this.accessError(errorCode, errorData); break;
                case 8: this.verifyError(errorCode, errorData); break;
                case 9: this.objectError(errorCode, errorData); break;
                case 103: this.paymentError(errorCode, errorData); break;
                case 1000: this.sessionExpired(errorCode, errorData); break;
                default: this.unknowError(errorCode, errorData);
             }
             
         },

         paymentError: () => {},
         permissionError: () => {},
         accessError: () => {},
         verifyError: () => {},
         objectError: () => {},
         
         sessionExpired: () => {},
         
         unknowError: () => {},
         
         install(Vue) {
            this.initSocketListiner();
            Vue.prototype.$request = this;
        }
            
    }