


    export default [
       {
           name: "user-tickets",
           path: "/dashboard/tickets",
           component: () => import("@/views/User/tickets"),
           meta: {
               group: "user"
           },
           children: [
               {
                    name: "user-tickets-compose",
                    path: "compose",
                    component: () => import("@/views/User/tickets/compose"),
                    meta: {
                        group: "user"
                    },
               }
           ]
       }    
    ]