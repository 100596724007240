


export default [
        
    { 
        path: "/dashboard/account/info",
        name: "user-account-info",
        component: () => import("@/views/User/account/account-types"),
        meta: {
            pageTitle: "Информация профиля",
            group: "user"
        }
    },
    { 
        path: "/dashboard/account/password",
        name: "user-account-password",
        component: () => import("@/views/User/account/account-password"),
        meta: {
            pageTitle: "Смена пароля",
            group: "user"
        }
    },
    { 
        path: "/dashboard/account/sessions",
        name: "user-account-sessions",
        component: () => import("@/views/User/account/account-sessions"),
        meta: {
            pageTitle: "История входов",
            group: "user"
        }
    },
    { 
        path: "/dashboard/account/settings",
        name: "user-account-settings",
        component: () => import("@/views/User/account/account-settings"),
        meta: {
            pageTitle: "Дополнительно",
            group: "user"
        }
    }
        
]