


    export default [
        {
            name: "admin-error-access-denied",
            path: ":module/access-denied",
            component: () => import("@/views/Administrator/Pages/access-denied"),
            meta: {
                group: "any",
                pageTitle: "Доступ запрещен"
            }
        }    
    ]