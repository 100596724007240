

    import $request from "@/modules/request/"
    import contracts from "./contracts"
    import blockchain from "@/modules/blockchain/"
    import { setLocale } from "@/libs/i18n/modules/init-locale"
    
    export default {
            
        contracts,     
            
        authorized: false,
        
        self: localStorage.getItem('current:user') ? JSON.parse( localStorage.getItem('current:user') ) : null,
        
        currency: "USD",
        
        getSymbol() {
            return blockchain.symbols[this.currency];
        },
        
        get() {
          return new Promise((resolve, reject) => {
              
              if( localStorage.getItem('current:user') ) {
                  this.self = JSON.parse( localStorage.getItem('current:user') );
              }
                              
              $request.get("user.get").then((result) => {
                  this.self = result;

                  localStorage.setItem( 'current:user', JSON.stringify(this.self) );

                  if (localStorage.getItem('current_locale') && 
                  localStorage.getItem('current_locale') !== 
                        result.locale
                  ) {
                    this.changeLocale(localStorage.getItem('current_locale'));
                  } else {
                    this.changeLocale(result.locale);
                  }

                  if (!localStorage.getItem("utmTags") && result.regutm) {
                        let utm_tags = {
                          'utm_source': result.regutm.utm_source ? result.regutm.utm_source : '',
                          'utm_medium': result.regutm.utm_medium ? result.regutm.utm_medium : '',
                          'utm_campaign': result.regutm.utm_campaign ? result.regutm.utm_campaign : '',
                          'utm_content': result.regutm.utm_content ? result.regutm.utm_content : '',
                          'utm_term': result.regutm.utm_term ? result.regutm.utm_term : '',
                        }
              
                        let parsedUtm = JSON.stringify(utm_tags);
                        localStorage.setItem("utmTags", parsedUtm);
                  }

                  resolve(this.self);
              }).catch((err) => {
                  reject(err);
              });
              
          });
        },

        hasPermissions( module, action ) {
            let permission = this.self.permissions.find(item => item.registry.key === module );
            if( !permission ) return false;
            return permission[action];
        },

        changeLocale(locale) {
            if (this.isAuth()) {
                let time_zone = this.getTimeZone();
                
                $request.post('user.updateLocale', {
                    locale: locale,
                    time_zone: time_zone || null
                }).then((res) => {
                    setLocale(locale)
                });
            } else {
                setLocale(locale)
            }
        },

        logout() {
           
        },

        isAuth() {

          let token = localStorage.getItem("access_token") || sessionStorage.getItem("access_token");

          if( token ) {
              this.authorized = true;
          } else {
              this.authorized = false;
          }

          return this.authorized;

        },
        useWelcome() {
            $request.post("user.useWelcome").then((result) => {
                this.self.is_welcome = true;
            });
        },
        getTimeZone() {
            let offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
            return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
        },
        install(Vue) {
            Vue.prototype.$user = this;            
        },
            
    }