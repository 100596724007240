


    export default [
        {
           path: "tickets",
           name: "admin-tickets",
            component: () => import("@/views/Administrator/Tickets/"),
            meta: {
                pageTitle: "Тикеты",
                group: "administrator"
            },
        },
        {
            path: "tickets/view/:id",
            name: "admin-tickets-view",
            component: () => import("@/views/Administrator/Tickets/view"),
            props: true,
            meta: {
                pageTitle: "Тикеты",
                group: "administrator"
            },
        },
    ]