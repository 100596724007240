

import contracts from "./contracts/user"
import settings from "./settings/user"
import miners from "./miners/user"
import dashboard from "./dashboard"
import tickets from "./tickets/user"

export default [
    { 
        path: "/dashboard",
        component: () => import("@/views/User/dashboard/dashboard"),
        meta: {
            pageTitle: "Сводка данных",
            group: "user"
        },
        children: dashboard
    },
    { 
        path: "/dashboard/charts/:name",
        name: "user-dashboard-chart",
        component: () => import("@/views/User/dashboard/dashboard-charts-view"),
        meta: {
            group: "user"
        }
    },
    {
        path: "/dashboard/statistic/",
        name: "user-statistic-picker",
        component: () => import("@/views/User/statistic/picker"),
        meta: {
            pageTitle: "Статистика",
            group: "user"
        }
    },
    {
        path: "/dashboard/:group/statistic/",
        name: "user-statistic-cloud",
        component: () => import("@/views/User/statistic/statistic"),
        meta: {
            pageTitle: "Статистика",
            group: "user"
        }
    },
    {
        path: "/dashboard/:group/statistic/",
        name: "user-statistic-miners",
        component: () => import("@/views/User/statistic/statistic"),
        meta: {
            pageTitle: "Статистика",
            group: "user"
        }
    },
    {
        path: "/dashboard/calculator",
        name: "user-calculator",
        component: () => import("@/views/User/calculator/calculator"),
        meta: {
            pageTitle: "Калькулятор расчета прибыли",
            group: "user"
        }
    },
    {
        path: "/dashboard/account",
        name: "user-account",
        component: () => import("@/views/User/account/account"),
        meta: {
            pageTitle: "Аккаунт",
            group: "user"
        }
    },
    // {
    //     path: "/dashboard/transactions",
    //     name: "user-transactions",
    //     component: () => import("@/views/User/transactions/transactions"),
    //     meta: {
    //         pageTitle: "Транзакции",
    //         group: "user"
    //     }
    // },
    {
        path: "/documents",
        name: "user-documents",
        component: () => import("@/views/User/documents/documents"),
        meta: {
            visibility: "public",
            pageTitle: "Документы",
            group: "user"
        }
    },
    {
        path: "/documents/:type",
        name: "user-documents-noauth",
        component: () => import("@/views/User/documents/documents"),
        meta: {
            visibility: "public",
            pageTitle: "Документы",
            group: "user"
        }
    },
    {
        path: "/dashboard/wallet",
        name: "user-wallet",
        component: () => import("@/views/User/wallet/wallet"),
        meta: {
            pageTitle: "Кошелек",
            group: "user"
        }
    },
    {
        path: "/dashboard/miners",
        name: "user-miners",
        component: () => import("@/views/User/miners/"),
        children: miners,
        meta: {
            pageTitle: "Майнеры",
            group: "user"
        }
    },
    { 
        path: "/dashboard/news",
        name: "user-news",
        component: () => import("@/views/User/news"),
        meta: {
            pageTitle: "Новости",
            group: "user"
        },
    },
    { 
        path: "/dashboard/news/view/:slug",
        name: "user-news-view",
        component: () => import("@/views/User/news/view"),
        meta: {
            pageTitle: "Новости",
            group: "user"
        },
    },
    { 
        path: "/dashboard/referral",
        name: "user-referral",
        component: () => import("@/views/User/referral/referral"),
        meta: {
            pageTitle: "Партнерская программа",
            group: "user"
        },
    },
    { 
        path: "/dashboard/orders",
        name: "user-orders",
        component: () => import("@/views/User/orders"),
        meta: {
            pageTitle: "Заявки",
            group: "user"
        },
    },
    ...contracts,
    ...settings,
    ...tickets
]