    
    
    export default [
        {
            path: "catalog",
            name: "user-miners-catalog",
            component: () => import("@/views/User/miners/catalog"),
            meta: {
                pageTitle: "Каталог майнеров",
                group: "user"
            },
        },
        {
            path: "catalog/item/id:item",
            name: "user-miners-item",
            component: () => import("@/views/User/miners/item/"),
            meta: {
                pageTitle: "Каталог майнеров",
                group: "user"
            },
        },
//        {
//            path: "list",
//            name: "user-miners-list",
//            component: () => import("@/views/User/miners/list"),
//            meta: {
//                pageTitle: "Майнеры",
//                group: "user"
//            },
//        },
        {
            path: "checkout",
            name: "user-miners-checkout",
            component: () => import("@/views/User/miners/checkout"),
            meta: {
                pageTitle: "Оформить заказ",
                group: "user"
            },
        },
    ]