export default [
    {
        path: "/",
        name: "home-page",
        visibility: "public",
        component: () => import("@/views/Main/main-design-v2/views/home"),
        meta: {
            redirectIfLoggedIn: true,
            layout: 'nd',
            title: 'Платформа для майнинга криптовалют | Qubity',
            md: true,
            group: "user"
        },
    },
    {
        path: "/vacancies",
        name: "open-vacancies",
        visibility: "public",
        component: () => import("@/views/Main/main-design-v2/views/vacancy/index"),
        meta: {
            redirectIfLoggedIn: true,
            layout: 'nd',
            title: 'Вакансии | Qubity',
            md: true
        },
    },
    {
        path: "/news",
        name: "news-blog",
        visibility: "public",
        component: () => import("@/views/Main/main-design-v2/views/news/index"),
        meta: {
            redirectIfLoggedIn: true,
            layout: 'nd',
            title: 'Новости | Qubity',
            md: true
        }
    },
    {
        path: "/news/:slug",
        name: "news-read",
        visibility: "public",
        component: () => import("@/views/Main/main-design-v2/views/news-page/index"),
        meta: {
            redirectIfLoggedIn: true,
            layout: 'nd',
            title: 'Новость | Qubity',
            md: true,
            group: "user"
        },
    },
    {
        path: "/knowledge",
        name: "knowledge",
        visibility: "public",
        component: () => import("@/views/Main/main-design-v2/views/knowledge/index"),
        // redirect: "/knowledge/wallet",
        meta: {
            redirectIfLoggedIn: true,
            layout: 'nd',
            title: 'База знаний | Qubity',
            md: true,
            group: "user"
        },
        children: [
            {
                path: ":slug",
                name: "knowledge-read",
                visibility: "public",
                // component: () => import("@/views/Main/main-design-v2/views/knowledge/pages/Wallet"),
                component: () => import("@/views/Main/main-design-v2/views/knowledge/page"),
                meta: {
                    redirectIfLoggedIn: true,
                    layout: 'nd',
                    title: 'База знаний | Qubity',
                    md: true,
                    group: "user"
                },
            }
        ]
    },
]

