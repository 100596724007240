export default [
    {
        path: "categories",
        name: "admin-knowledge-categories",
        component: () => import("@/views/Administrator/Knowledge/categories"),
        props: true,
        meta: {
            pageTitle: "Категории",
            group: "administrator"
        },
    },
    {
        path: "",
        name: "admin-knowledge-list",
        component: () => import("@/views/Administrator/Knowledge/list"),
        meta: {
            pageTitle: "База знаний",
            group: "administrator"
        },
    },
    {
        path: "add",
        name: "admin-knowledge-add",
        component: () => import("@/views/Administrator/Knowledge/add"),
        props: true,
        meta: {
            pageTitle: "Пополнить базу знаний",
            group: "administrator"
        },
    },
    {
        path: "edit",
        name: "admin-knowledge-update",
        component: () => import("@/views/Administrator/Knowledge/update"),
        props: true,
        meta: {
            pageTitle: "Редактировать пост",
            group: "administrator"
        },
    }
]