<template>
  <div class="headline__wrapper" :class="centered && 'text-center'">
    <div class="d-inline-block position-relative">
      <img v-if="sweep" class="position-absolute sweep d-none d-md-block" src="@/@core/assets/images/btn_sweep.svg">
      <component :is="'h' + level" :class="'q-header q-header-' + size" :style="'color: ' + color">
        <slot></slot>
      </component>
    </div>
  </div>
</template>

<script>
export default {
  name: "QHeader",
  props: {
    sweep: {type: Boolean, default: false},
    level: {type: String, default: "2"},
    size: {type: String, default: "xl"},
    centered: {type: Boolean, default: true},
    color: {type: String, default: "var(--theme-accent-dark)"}
  }
}
</script>

<style lang="scss">
@import "../src/@core/scss/new-design";

.sweep {
  left: -30px;
  top: -20px;
  transition: all .3s ease;
}

.headline__wrapper {
  h1, h2, h3, h4, h5, h6 {
    font-weight: 400!important;
  }
}

.q-header {
  b {
    font-weight: 600!important;
  }
}

@media screen and (min-width: 1200px) {
  .q-header-xxl {
    font-size: 48px!important;
  }

  .q-header-xl {
    font-size: 40px!important;
  }

  .q-header-l {
    font-size: 32px!important;
  }

  .q-header-m {
    font-size: 24px!important;
  }

  .q-header-s {
    font-size: 18px!important;
  }
}

@media screen and (max-width: 1200px) {
  .q-header-xl, .q-header-xxl {
    font-size: 32px!important;
  }

  .q-header-l {
    font-size: 24px!important;
  }

  .q-header-m {
    font-size: 20px!important;
  }

  .q-header-s {
    font-size: 16px!important;
  }
}

@media screen and (max-width: 768px) {
  .q-header-xl, .q-header-xxl {
    font-size: 24px!important;
  }

  .q-header-l {
    font-size: 20px!important;
  }

  .q-header-m {
    font-size: 18px!important;
  }

  .q-header-s {
    font-size: 16px!important;
  }
}
</style>
