
    export default [
        {
            path: "/change-email-confirm/:link",
            name: "change-email-confirm",
            component: () => import("@/views/User/security/change-email-confirm"),
            meta: {
                layout: 'custom',
                group: "user"
            }
        },

        {
            path: "/public-offer",
            name: "page-public-offer",
            component: () => import("@/views/Main/docs/public-offer"),
            meta: {
                layout: 'custom',
                visibility: 'public',
                pageTitle: "title-pages.public-offer",
                group: "user"
            }
        },

        {
            path: "/policy",
            name: "page-policy",
            component: () => import("@/views/Main/docs/policy"),
            meta: {
                layout: 'custom',
                visibility: 'public',
                pageTitle: "title-pages.privacy-policy",
                group: "user"
            }
        },

        {
            path: "/policy-en",
            name: "page-policy-en",
            component: () => import("@/views/Main/docs/policy-en"),
            meta: {
                layout: 'custom',
                visibility: 'public',
                pageTitle: "Privacy policy",
                group: "user"
            }
        },

        {
            path: 'PageNotFound404',
            name: 'page-not-found',
            component: () => import('@/views/Main/main-design-v2/views/404'),
            meta: {
                redirectIfLoggedIn: true,
                layout: 'nd',
                title: 'Ничего не нашлось | Qubity',
                md: true,
                bluebg: true
            },
        },

        {
            path: '/payment/success',
            name: 'payment-success',
            component: () => import('@/views/payments/Success.vue'),
            meta: {
                visibility: 'public',
                layout: 'custom',
            },
        },

        {
            path: '/payment/error',
            name: 'payment-error',
            component: () => import('@/views/payments/Error.vue'),
            meta: {
                visibility: 'public',
                layout: 'custom',
            },
        },

        {
            path: '/payment/pending',
            name: 'payment-pending',
            component: () => import('@/views/payments/Pending.vue'),
            meta: {
                visibility: 'public',
                layout: 'custom',
            },
        },

    ]
