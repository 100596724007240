    export default [
        {
            path: "",
            name: "admin-vacancies-list",
            component: () => import("@/views/Administrator/Vacancies/list"),
            meta: {
                pageTitle: "Вакансии",
                group: "administrator"
            },
        },
        {
            path: "add",
            name: "admin-vacancies-add",
            component: () => import("@/views/Administrator/Vacancies/add"),
            props: true,
            meta: {
                pageTitle: "Добавить вакансию",
                group: "administrator"
            },
        },
        {
            path: "edit",
            name: "admin-vacancies-update",
            component: () => import("@/views/Administrator/Vacancies/update"),
            props: true,
            meta: {
                pageTitle: "Редактировать вакансию",
                group: "administrator"
            },
        }
    ]