

    import $request from "@/modules/request/"
    import emcd from "./emcd"

    export default {
        emcd,
        
        stats: {},   
        tickers: {
            values: {
                USD: { last: 44000, symbol: "$" },
                BTC: { last: 1, symbol: "₿" }
            },
            listOf: [],            
        }, 
        
        aviable: [ "USD", "RUB" ],
        symbols: {
            "USD" : "$",
            "RUB" : "₽",
            "BTC" : "₿"
        },
        
        get() {
            
            require('axios')({
                url: "https://api.blockchain.info/stats",
                method: "get"
            }).then( response => {
                this.stats = response.data;
            });
            
            $request.get("utils.tickers").then( tickers => {
                
                this.tickers.values = tickers;
                
                this.tickers.values['BTC'] = {
                    last: 1,
                    symbol: this.symbols['BTC']
                }
                
                this.tickers.listOf = [];
                
                Object.keys( tickers ).forEach(key => {
                    if( this.aviable.indexOf(key) >= 0 ) {
                        this.tickers.listOf.push({
                           ticker: key,
                           value: this.tickers.values[key].last,
                           symbol: this.symbols[key],
                        });
                    }
                });
                
                this.tickers.listOf.push({
                   ticker: "BTC",
                   value: 1,
                   symbol: "BTC",
                });
                
            });
            
            
        },
        
        install(Vue) {
            Vue.prototype.$blockchain = this;
        }
            
    }