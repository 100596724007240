


    export default [
        
        {
            path: "/dashboard/contracts/new/",
            name: "user-contracts-picker",
            component: () => import("@/views/User/contracts/picker"),
            meta: {
                pageTitle: "Новый контракт",
                group: "user",
                breadcrumb: [
                    {
                      text: 'Контракты',
                    },
                    {
                      text: 'Новый',
                      active: true,
                    },
                ],
            }
        },
                   
        {
            path: "/dashboard/contracts/create/",
            name: "user-contracts-create",
            component: () => import("@/views/User/contracts/create/create"),
            meta: {
                pageTitle: "Новый контракт",
                group: "user",
                breadcrumb: [
                    {
                      text: 'Контракты',
                    },
                    {
                      text: 'Новый',
                      active: true,
                    },
                ],
            }
        },
        
        {
            path: "/dashboard/contracts/:id",
            name: "user-contract-details",
            component: () => import("@/views/User/contracts/contract"),
            meta: {
                group: "user"
            }
        },
        
        {
            path: "/dashboard/contracts/:id/:miner",
            name: "user-contract-miner-details",
            component: () => import("@/views/User/contracts/miner"),
            props: true,
            meta: {
                group: "user"
            }
        } 
    ]