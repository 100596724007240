<template>
  <div class="q-btn__wrapper position-relative" :class="block ? 'd-block' : 'd-inline-block'">
    <img alt="" v-if="sweep" class="position-absolute sweep" src="@/@core/assets/images/btn_sweep.svg">
    <b-button @click="$emit('click', $event)" class="q-btn" :class="smallPadding && 'small-padding'" :variant="variant" :block="block" v-bind="$attrs">
      <slot></slot>
    </b-button>
  </div>
</template>

<script>
export default {
  name: "q-btn",
  props: {
    variant: {type: String, default: "primary"},
    sweep: {type: Boolean, default: false},
    block: {type: Boolean, default: false},
    smallPadding: {type: Boolean, default: false}
  }
}
</script>

<style lang="scss" scoped>
@import "src/@core/scss/new-design";

.q-btn__wrapper {
  appearance: none!important;
  -webkit-appearance: none!important;

  .sweep {
    left: -30px;
    top: -20px;
    transition: all .3s ease;
  }

  &:hover {
    .sweep {
      left: -35px;
      top: -25px
    }
  }
}

.q-btn {
  font-family: 'Euclid Circular B', serif!important;

  &.btn-primary {
    background-color: var(--theme-accent-light)!important;
  }

  &.btn-secondary {
    background-color: var(--theme-bg-light)!important;
    border: none;
    color: var(--theme-accent-light)!important;
  }

  font-size: 20px!important;
  padding: 20px 48px!important;
  border-radius: 999px!important;
}

.small-padding {
  padding: 12px 20px!important;
}

@media screen and (max-width: 992px) {
  .q-btn {
    font-size: 18px!important;
  }
}

@media screen and (max-width: 768px) {
  .q-btn {
    font-size: inherit!important;
  }
}


</style>
