


   export default [
        {
            name: "admin-users-list",
            path: "/app/users",
            props: true,
            meta: {
                group: "administrator"
            },
            component: () => import("@/views/Administrator/Users/list")
        },
        {
            name: "admin-users-view",
            path: "/app/users/view/id:id",
            props: true,
            meta: {
                group: "administrator"
            },
            component: () => import("@/views/Administrator/Users/view")
        },
        {
            name: "admin-users-list",
            path: "/app/users/id:id/edit",
            props: true,
            meta: {
                group: "administrator"
            },
            component: () => import("@/views/Administrator/Users/edit")
        }
   ]