

    import $request from "@/modules/request/"
    
    export default {
        
        countries: [],
        
        get() {
            $request.get("utils.db").then(result => {
               Object.keys(result).forEach(key => {
                  if( this.hasOwnProperty( key ) ) {
                      this[key] = result[key];
                  }
               });
            });
        }  
        
            
            
    }