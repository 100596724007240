

    import $request from "@/modules/request/"

    export default {
            
        values: localStorage.getItem("values") ? JSON.parse(localStorage.getItem("values")) : {
            H: 1000000000000,
            R: 6.5,
            D: 28893045048575,
            P: 3.5,
            K: 0.06,
            W: 51,
            E: 0.003600,

            REFINVITEE: 1,
            REFINVITEEM: 1,

            REFINVITATION1223: 0,
            REFINVITATION2435: 0,
            REFINVITATION36: 0,
            REFINVITATIONM: 0

        },
        
        getValues() {
            $request.get("utils.getValues").then( result => {
                this.values.H = result.hrs;
                this.values.R = result.reward;
                this.values.D = result.difficult;
                this.values.P = result.thsp;
                this.values.K = result.kvth;
                this.values.W = result.wpt;
                this.values.E = result.egp;

                this.values.REFINVITATION1223 = result.ref_invitation_percent_12_23
                this.values.REFINVITATION2435 = result.ref_invitation_percent_24_35
                this.values.REFINVITATION36 = result.ref_invitation_percent_36
                this.values.REFINVITATIONM = result.ref_invitation_percent_m

                this.values.REFINVITEE = result.ref_invitee_percent
                this.values.REFINVITEEM = result.ref_invitee_percent_m

                localStorage.setItem("values", JSON.stringify( this.values) );
            });
        }
        
        
            
    }