

   export default [
        {
            path: "",
            name: "admin-news-list",
            component: () => import("@/views/Administrator/News/list"),
            meta: {
                pageTitle: "Новости",
                group: "administrator"
            },
        },
        {
            path: "categories",
            name: "admin-news-categories",
            component: () => import("@/views/Administrator/News/categories"),
            meta: {
                pageTitle: "Новости",
                group: "administrator"
            },
        },
        {
            path: "add",
            name: "admin-news-add",
            component: () => import("@/views/Administrator/News/add"),
            meta: {
                pageTitle: "Публикация",
                group: "administrator"
            },
        },
        {
            path: "edit/:slug",
            name: "admin-news-update",
            component: () => import("@/views/Administrator/News/update"),
            meta: {
                pageTitle: "Редактировать",
                group: "administrator"
            },
        }  
   ]