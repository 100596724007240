
    import $request from "@/modules/request/"

    import TimeAgo from 'javascript-time-ago'
    import ru from 'javascript-time-ago/locale/ru'
    
    TimeAgo.addDefaultLocale(ru);

    export default {
            
        listing: {
            last_update_time: -1,
            items: [],
            get() {
                $request.get("utils.listing").then( rsp => {
                    this.items = rsp.items;
                    this.last_update_time = rsp.last_update_time;
                });
            },
        },    
            
        hexToRgba( hex ) {
            var shortRegEx = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
            hex = hex.replace(shortRegEx, function(full, r, g, b) {
              return [r, r, g, g, b, b].join();
            });
            var longRegEx = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?/i;
            var rgbArray  = longRegEx.exec(hex);
            var rgbObj = rgbArray ? {
              r: parseInt(rgbArray[1], 16),
              g: parseInt(rgbArray[2], 16),
              b: parseInt(rgbArray[3], 16)
            } : null;
            return rgbObj;
        },    
            
        getTextColor(bgColor) {

            var nThreshold = 105;
            var components = this.getRGBComponents(bgColor);
            var bgDelta = (components.R * 0.299) + (components.G * 0.587) + (components.B * 0.114);

            return ((255 - bgDelta) < nThreshold) ? "#000000" : "#ffffff";   
        },

        getRGBComponents(color) {       

             var r = color.substring(1, 3);
             var g = color.substring(3, 5);
             var b = color.substring(5, 7);

             return {
                R: parseInt(r, 16),
                G: parseInt(g, 16),
                B: parseInt(b, 16)
             };
        },    
            
        formatBytes(bytes, decimals = 2) {
            
            if (bytes === 0) return '0 Bytes';

            const k = 1024;
            const dm = decimals < 0 ? 0 : decimals;
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

            const i = Math.floor(Math.log(bytes) / Math.log(k));

            return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            
        },     
            
        preferBeforeUploadAction( file ) {
            
            return new Promise(( resolve, reject ) => {
                let type = file.type.split("/")[0];
            
                switch( type ) {
                    case "image" : this.getImageCover(file).then( resolve ); break;
                    case "video" : this.getVideoCover(file, 0.1 ).then( resolve ); break;
                }
                
            });
            
        },
        
        getImageCover( file ) {
            
            return new Promise(( resolve, reject) => {
                var image = new Image();
                var objectUrl = URL.createObjectURL(file);
                image.onload = function () {
                    
                    resolve({
                        type: "image",
                        placeholder: objectUrl,
                        thumb: objectUrl,
                        width: this.width,
                        height: this.height
                    });
                                        
                };
                image.src = objectUrl;
            });
            
        },
        
        getVideoCover(file, seekTo = 0.0) {
                        
            return new Promise((resolve, reject) => {
                // load the file to a video player
                const videoPlayer = document.createElement('video');
                videoPlayer.setAttribute('src', URL.createObjectURL(file));
                videoPlayer.load();
                videoPlayer.addEventListener('error', (ex) => {
                    reject("error when loading video file", ex);
                });
                // load metadata of the video to get video duration and dimensions
                videoPlayer.addEventListener('loadedmetadata', () => {
                    // seek to user defined timestamp (in seconds) if possible
                    if (videoPlayer.duration < seekTo) {
                        reject("video is too short.");
                        return;
                    }
                    // delay seeking or else 'seeked' event won't fire on Safari
                    setTimeout(() => {
                      videoPlayer.currentTime = seekTo;
                    }, 200);
                    // extract video thumbnail once seeking is complete
                    videoPlayer.addEventListener('seeked', () => {
                        // define a canvas to have the same dimension as the video
                        const canvas = document.createElement("canvas");
                        canvas.width = videoPlayer.videoWidth;
                        canvas.height = videoPlayer.videoHeight;
                        // draw the video frame to canvas
                        const ctx = canvas.getContext("2d");
                        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
                        // return the canvas image as a blob
                        ctx.canvas.toBlob(
                            blob => {
                                resolve({
                                    type: "video",
                                    thumb: URL.createObjectURL(blob),
                                    placeholder: URL.createObjectURL(blob),
                                    duration: Math.round(videoPlayer.duration),
                                    width: videoPlayer.videoWidth,
                                    height: videoPlayer.videoHeight
                                });
                            },
                            "image/jpeg",
                            0.75 /* quality */
                        );
                    });
                });
            });
        },    
           
        declOfNum(number, titles) {  
                    
            let cases = [2, 0, 1, 1, 1, 2];  

            if(number === 0) {
                return titles[2];
            }

            return titles[ (number % 100 > 4 && number % 100 < 20 )? 2 : cases[(number%10<5)?number%10:5] ]; 

        },   
           
        validURL(str) {
            var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
              '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
              '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
              '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
              '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
              '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
            return !!pattern.test(str);
        },   
        
        getTimeBetween( date1, date2 ) {
                
                var difference = date1.getTime() - date2.getTime();

                var daysDifference = Math.floor(difference/1000/60/60/24);
                difference -= daysDifference*1000*60*60*24

                var hoursDifference = Math.floor(difference/1000/60/60);
                difference -= hoursDifference*1000*60*60

                var minutesDifference = Math.floor(difference/1000/60);
                difference -= minutesDifference*1000*60

                var secondsDifference = Math.floor(difference/1000);
                
                return {
                    days: daysDifference,
                    hours: hoursDifference,
                    minutes: minutesDifference,
                    seconds: secondsDifference
                }
                
        },
        
        timeAgo( timestamp ) {
            return new TimeAgo('ru-RU').format( timestamp * 1000 ); 
        },

        daysAgo( timestamp ) {
            let now = new Date();
            let current = new Date( (timestamp) * 1000 );
            
            let date = 'через ';

            let timeDiff = Math.abs(current.getTime() - now.getTime());
            let daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

            if (daysDiff == 1) {
                return new TimeAgo('ru-RU').format( timestamp * 1000 ); 
            }

            date += daysDiff;
            date += ' ';
            date += this.declOfNum( daysDiff, [ 'день', 'дня', 'дней' ]); 

            return date;

        },
            
        lastActivityFormat( timestamp ) {
 
            var now = new Date();
            var current = new Date( (timestamp - 1) * 1000 );
            var date = '';

            var timeBetween =  this.getTimeBetween( now, current);

            if(timeBetween.days < 365) {

                if( timeBetween.days < 1 && now.getDay() === current.getDay() ) {

                    if( timeBetween.minutes < 15 ) {
                        return new TimeAgo('ru-RU').format(current);
                    }

                    date = "сегодня";

                } else if( timeBetween.days < 2 ) {
                    date = "вчера"
                } else {
                    date = date = new Intl.DateTimeFormat('ru-RU', { 'day': '2-digit', 'month' : '2-digit' }).format( new Date( timestamp * 1000 ) ).replace("г.", ''); 
                }

                var time = new Intl.DateTimeFormat('ru-RU', { 'hour' : '2-digit', 'minute' : '2-digit' }).format( new Date( timestamp * 1000 ) ).replace("г.", ''); 

                return `${date} в ${time}`;

            } else { 
                return new Intl.DateTimeFormat('ru-RU', { 'day': '2-digit', 'month' : '2-digit', 'year' : 'numeric' }).format( new Date( timestamp * 1000 ) );
            }

            return date;

        },    
            
        ellipsis(str) {
            if (str.length > 35) {
              return str.substr(0, 20) + '...' + str.substr(str.length-10, str.length);
            }
            return str;
        }, 
            
        historyData: {
             
            market_current_price: 0,
            market_price_avg: 0,
            market_price_percent: 0,

            get() {
                $request.get("utils.historyData").then(result => {
                    this.market_current_price = result.market_current_price;
                    this.market_price_avg = result.market_price_avg;
                    this.market_price_percent = result.market_price_percent;
                });
            }
             
        },   
            
        getChartColor() {
             
            var canvas = document.createElement('canvas');
            var ctx = canvas.getContext('2d');
            var gradient = ctx.createLinearGradient(0, 0, 0, 400);
            gradient.addColorStop(0, 'rgba(255, 125, 151, 0.2)');   
            gradient.addColorStop(1, 'rgba(255, 125, 151, 0)');
           
            return {
                backgroundColor: gradient,
                borderColor: "rgb(255, 125, 151)"
            }
            
        },  
        
        readingTime( string ) {
            
            var time, wordPerSec = 2;

            time = (string.replace(/(<([^>]+)>)/ig,"").split(" ").length) / wordPerSec;

            if (time < 50) {
                return parseInt(((time / 10) + 1) * 10) + " сек.";
            } else if(time < 3600) {
                return Math.ceil(time / 60) + " мин.";
            } else {
                return floor(time / 3600) + " ч.";
            }
                
	},
            
           
        getStatisticTime( timestamp ) {
                
            var a = new Date(timestamp * 1000);
            var months = ['Янв','Фев','Март','Апр','Май','Июнь','Июль','Авг','Сент','Окт','Нов','Дек'];

            var year = a.getFullYear();
            var month = months[a.getMonth()];
            var date = a.getDate();
            var hour = a.getHours();
            var min = a.getMinutes();
            var sec = a.getSeconds();

            if( hour < 10 ) {
                hour = `0${hour}`;
            }

            if( min < 10 ) {
                min = `0${min}`;
            }

            if( sec < 10 ) {
                sec = `0${sec}`;
            }

            return `${date} ${month} ${year}`;

        }
        
            
    }