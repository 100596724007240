


    export default [
        {
            path: "contracts",
            name: "admin-contracts-list",
            component: () => import("@/views/Administrator/Contracts/list"),
            meta: {
                pageTitle: "Контракты",
                group: "administrator",
            }
        }, 
        {
            path: "contracts/offers/",
            name: "admin-contracts-offers",
            component: () => import("@/views/Administrator/Contracts/Offers/list"),
            meta: {
                pageTitle: "Предложения",
                group: "administrator",
            }
        }, 
        {
            path: "contracts/offers/create",
            name: "admin-contracts-offers-create",
            component: () => import("@/views/Administrator/Contracts/Offers/create"),
            meta: {
                pageTitle: "Предложения",
                group: "administrator",
            }
        },
        {
            path: "contracts/offers/edit",
            name: "admin-contracts-offers-edit",
            component: () => import("@/views/Administrator/Contracts/Offers/edit"),
            props: true,
            meta: {
                pageTitle: "Предложения",
                group: "administrator",
            }
        },
        {
            path: "contracts/offers/edit/banner",
            name: "admin-contracts-offers-edit-banner",
            component: () => import("@/views/Administrator/Contracts/Offers/editBanner"),
            props: true,
            meta: {
                pageTitle: "Предложения",
                group: "administrator",
            }
        },
        {
            path: "contracts/offers/add",
            name: "admin-contracts-offers-add",
            component: () => import("@/views/Administrator/Contracts/Offers/add"),
            props: true,
            meta: {
                pageTitle: "Предложения",
                group: "administrator",
            }
        },
        {
            path: "contracts/:id",
            name: "admin-contracts-view",
            component: () => import("@/views/Administrator/Contracts/view"),
            meta: {
                pageTitle: "Контракты",
                group: "administrator",
            }
        },
        {
            path: "contracts/:id/:miner",
            name: "admin-contracts-miner-view",
            component: () => import("@/views/Administrator/Contracts/Miner/view"),
            meta: {
                pageTitle: "Контракты",
                group: "administrator",
            }
        },
        
    ]